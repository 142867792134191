import cn from 'classnames';

import type {HeaderProps} from '@/components/brochureV2/section/Header/types';
import useGlobalNav from '@/hooks/navigation/global';
import Anchor from '@/components/shared/Anchor/Anchor';
import Button from '@/components/base/elements/Button/Button';
import {useTranslations} from '@/hooks/useTranslations';
import Header from '@/components/brochureV2/section/Header/Header';
import {HeaderBackgroundColorScheme} from '@/enums';

interface CaseStudiesHeaderProps extends HeaderProps {
  modalOpeningHref?: string;
  leadRoutingValue?: string;
}

export default function CaseStudiesHeader(props: CaseStudiesHeaderProps) {
  const {
    background,
    modalOpeningHref = '#contact-sales',
    leadRoutingValue = 'plus',
    ...restProps
  } = props;
  const backgroundColorScheme =
    props?.background || HeaderBackgroundColorScheme.TransparentLight;
  const {t} = useTranslations();
  const {loginUrl, loginText} = useGlobalNav();
  const darkBackground =
    backgroundColorScheme === HeaderBackgroundColorScheme.Black;

  const customDesktopLinks = (
    <>
      <li className="mr-8 text-base">
        <Anchor
          className={cn('whitespace-nowrap hover:underline', {
            'text-white': darkBackground,
            'text-black': !darkBackground,
          })}
          href={loginUrl}
          componentName="login"
        >
          {loginText}
        </Anchor>
      </li>
      <li className="desktop-header-button">
        <Button
          href={modalOpeningHref}
          mode={darkBackground ? 'dark' : 'light'}
          size="small"
          className="py-2 px-5 text-base font-bold whitespace-nowrap"
        >
          {t(`header.cta.text.${leadRoutingValue}`)}
        </Button>
      </li>
    </>
  );

  const customMobileLinks = (
    <li className="mr-8 text-base">
      <Anchor
        className={cn('whitespace-nowrap hover:underline', {
          'text-white': darkBackground,
          'text-black': !darkBackground,
        })}
        href="#contact-sales"
        componentName="login"
      >
        {t(`header.cta.text.${leadRoutingValue}`)}
      </Anchor>
    </li>
  );

  const customMobileMenuCtaLinks = (
    <>
      <li className="py-1 sm:grow sm:pt-2">
        <Button
          href={loginUrl}
          intent="secondary"
          className="w-full py-2 px-5 text-base font-bold"
          mode={darkBackground ? 'dark' : 'light'}
          componentName="login"
        >
          {loginText}
        </Button>
      </li>
      <li className="pt-1 sm:grow sm:pt-2">
        <Button
          href={modalOpeningHref}
          className="w-full py-2 px-5 text-base font-bold"
          mode={darkBackground ? 'dark' : 'light'}
        >
          {t(`header.cta.text.${leadRoutingValue}`)}
        </Button>
      </li>
    </>
  );

  return (
    <Header
      {...restProps}
      CustomDesktopCtaLinklist={customDesktopLinks}
      CustomMobileCtaLinklist={customMobileLinks}
      CustomMobileMenuCtaLinklist={customMobileMenuCtaLinks}
      background={backgroundColorScheme}
    />
  );
}
