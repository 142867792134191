import type {Handle} from '@/types';

const driftBotLocales = new Set(['en', 'es', 'de', 'ja', 'it', 'fr', 'zh-CN']);

export const sharedHandle: Handle = {
  driftBot({site}) {
    const localePrefix = site.locale.split('-')[0];
    return (
      driftBotLocales.has(site.locale) || driftBotLocales.has(localePrefix)
    );
  },
};
